// cta style hear
.container-cta{
    max-width: 1920px;
    margin: auto;
    background: var(--color-primary);
    padding: 0 20px;
    @media #{$smlg-device} {
        background: #0C0A0A;
    }
}
.single-cta-area.bg{
    background-image: url(../images/cta/01.jpg);
    background-position: center;
    background-size: cover;
    height: 100%;
    @media #{$smlg-device} {
        background-image: none;
    }
    .inner{
        margin-left: 100px;
        display: flex;
        align-items: center;
        @media #{$smlg-device} {
            margin-left: 25px;
        }
        .icon-area{
            margin-right: 20px;
        }
    }
    .dec-area{
        span{
            font-family: var(--font-primary);
            color: #fff;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            text-transform: uppercase;
        }
        .title{
            margin-bottom: 0;
            color: #fff;
            font-weight: 700;
            font-size: 30px;
            line-height: 46px;
            @media #{$laptop-device} {
                font-size: 22px;
            }
            @media #{$smlg-device} {
                font-size: 22px;
            }
        }
    }
}
.single-cta-area{
    .inner{
        margin-left: 100px;
        display: flex;
        align-items: center;
        @media #{$smlg-device} {
            margin-left: 60px;
        }
        @media #{$sm-layout} {
            margin-left: 25px;
        }
        .icon-area{
            margin-right: 20px;
        }
    }
    .dec-area{
        span{
            font-family: var(--font-primary);
            color: #fff;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            text-transform: uppercase;
        }
        .title{
            margin-bottom: 0;
            color: #fff;
            font-weight: 700;
            font-size: 30px;
            line-height: 46px;
            @media #{$laptop-device} {
                font-size: 22px;
            }
            @media #{$smlg-device} {
                font-size: 22px;
            }
        }
    }
}
// experience area
.bg-experience{
    background-image: url(../images/cta/02.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}
.rts-experience-area{
    .experience-main-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media(max-width:991px){
            display: grid;
        }
        .title{
            color: #fff;
            margin-bottom: 7px;
        }
        .left{
            @media(max-width:991px){
                order: 2;
            }
            .title{
                font-weight: 600;
                font-size: 48px;
                line-height: 73px;
                font-family: var(--font-primary);
                @media(max-width:768px){
                    line-height: 55px;
                }
                @media(max-width:450px){
                    font-size: 30px;
                    line-height: 40px;
                }
            }
            p.disc{
                color: #6E777D;
                font-weight: 500;
                font-size: 18px;
                line-height: 26px;
                margin-bottom: 0;
                @media(max-width:768px){
                    br{
                        display: none;
                    }
                }
            }
        }
        .right{
            @media(max-width:991px){
                margin-bottom: 20px;
            }
            .title{
                font-size: 120px;
                overflow: hidden;
                @media(max-width:991px){
                    overflow: visible;
                    margin-bottom: 50px;
                }
                @media(max-width:768px){
                    font-size: 90px;
                }
                @media(max-width:450px){
                    font-size: 75px;
                }
            }
            span{
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                text-transform: uppercase;
                font-family: var(--font-primary);
                letter-spacing: 0.1em;
                color: #fff;
                margin-top: -20px;
                display: block;
                margin-left: 7px;
                &.counter{
                    font-size: 40px;
                }
            }
        }
    }
}
.container-cta-150{
    max-width: 1620px;
    margin: auto;
    clip-path: polygon(5% 0, 100% 0%, 95% 100%, 0% 100%);
    height: 220px;
    background: linear-gradient(-70deg, #171717 50%, #F0F1F2 50%);
    @media(max-width:1440px){
        height: auto;
    }
    @media(max-width:991px){
        background: linear-gradient(-70deg, #171717 50%, #171717 50%);
    }
    @media #{$large-mobile} {
        clip-path: none;
    }
    .path-cta-left{
        margin-left: 150px;
        padding: 60px 0;
        @media(max-width:1400px){
            margin-left: 60px;
        }
        @media(max-width:991px){
            text-align: center;
            margin: 0;
        }
        @media(max-width:576px){
            padding: 60px 20px;
        }
        .title{
            color: #0C0A0A;
            font-weight: 600;
            font-size: 40px;
            line-height: 61px;
            margin-bottom: 5px;
            @media(max-width:991px){
                color: #ffffff;
            }
            @media(max-width:576px){
                font-size: 30px;
                line-height: 50px;
            }
        }
        p{
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #6E777D;
            @media(max-width:991px){
                color: #ffffff;
            }
        }
    }
    // path area start
    .path-call-area{
        display: flex;
        align-items: center;
        margin-left: 150px;
        padding: 60px 0;
        @media(max-width:1400px){
            margin-left: 40px;
        }
        @media(max-width:991px){
            justify-content: center;
            margin: 0;
            padding-top: 0;
        }
        @media(max-width:576px){
            padding: 60px 20px;
            padding-top: 0;
        }
        .icon{
            margin-right: 13px;
            i{
                width: 100px;
                height: 100px;
                background: #fff;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--color-primary);
                font-weight: 200;
                font-size: 40px;
                @media(max-width:1200px) and (min-width:991px){
                    width: 70px;
                    height: 70px;
                    font-size: 28px;
                }
                @media(max-width:576px){
                    width: 70px;
                    height: 70px;
                    font-size: 28px;
                }
            }
        }
        .detail{
            span{
                display: block;
                text-transform: capitalize;
                letter-spacing: 0.1em;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                font-family: var(--font-primary);
                color: var(--color-white);
                text-transform: uppercase;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
            }
            a.number{
                font-weight: 700;
                font-size: 24px;
                color: #fff;
                line-height: 37px;
                transition: 0.3s;
                font-weight: 700;
                font-size: 40px;
                line-height: 61px;
                @media(max-width:576px){
                    font-size: 30px;
                    line-height: 50px;
                }
            }
        }
    }
}


// cta style eight hear
.rts-cta-eight{
    background-image: url(../images/cta/05.jpg);
}
.rts-cta-eight{
    .cta-main-wrapper{
        display: flex;
        align-items: center;
        @media #{$smlg-device} {
            flex-direction: column;
            align-items: flex-start;
        }
        .cta-title-wrapper{
            span{
                color: #fff;
                font-weight: 700;
                font-size: 14px;
                line-height: 21px;
                font-family: var(--font-primary);
            }
            .title{
                color: #fff;
                font-weight: 700;
                font-size: 48px;
                line-height: 58px;
                @media #{$large-mobile} {
                    font-size: 26px;
                    line-height: 42px;
                }
            }
        }
        .cta-content-wrapper{
            margin-left: auto;
            background: #fff;
            padding: 40px;
            display: flex;
            @media #{$smlg-device} {
                margin-left: inherit;
            }
            @media #{$sm-layout} {
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;
            }
            @media #{$large-mobile} {
                padding: 10px; 
            }
            .single-info{
                display: flex;
                align-items: center;
                @media #{$sm-layout} {
                    display: flex;
                    align-items: center;
                }
                i{
                    width: 60px;
                    height: 60px;
                    background: var(--color-primary);
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    margin-right: 20px;
                }
                &.border-right{
                    border-right: 1px solid #EBEDF8;
                }
                .info{
                    span{
                        display: block;
                    }
                    a{
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 30px;
                        color: #0C0A0A;
                        font-family: var(--font-primary);
                        transition: .3s;
                        &:hover{
                            color: var(--color-primary);
                        }
                    }
                }
            }
        }
    }
}
.bg-blog-8{
    background: #F7F7F7;
}

.bg-appoinment-short{
    background-image: url(../images/appoinment/03.jpg) !important;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    overflow: hidden;
    background-size: cover;
    .single-details-appoinment{
        position: absolute;
        right: 25%;
        width: 450px;
        top: 20%;
        background: #FF6600;
        height: 450px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 35px;
        @media #{$smlg-device} {                    
            padding: 20px;
            position: absolute;
            right: 20%;
            width: 350px;
            top: 44%;
            background: #FF6600;
            height: 350px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
        @media #{$md-layout} {
            display: none;
        }
        @media #{$sm-layout} {
            display: none;
        }
        .title{
            color: #fff;
            font-size: 48px;
            font-weight: 600;
            margin-bottom: 10px;
            @media #{$smlg-device} {
                color: #fff;
                font-size: 33px;
                font-weight: 600;
                margin-bottom: 3px;
            }
        }
        p{
            margin-bottom: 20px;
            color: #fff;
        }
        p.disc{
            color: #fff;
            margin-bottom: 0;
        }
        &.small{
            top: -10%;
            right: 5%;
            background: #101010 !important;
            width: 350px;
            height: 350px;
            @media #{$smlg-device} {
                top: -2%;
                right: 5%;
                background: #101010 !important;
                width: 250px;
                height: 250px;
            }
            p.disc{
                font-size: 12px;
                br{
                    display: none;
                }
            }
        }
    }
}

.appoinment-inner-main-wrapper{
    .pre{
        color: var(--color-primary);
        text-transform: uppercase;
    }
    .title{
        color: #fff;
        font-weight: 600;
        font-size: 48px;
        line-height: 58px;
        margin-top: 5px;
        @media #{$small-mobile} {
            font-size: 26px;
            line-height: 43px;
        }
    }
    a.rts-btn{
        border: 2px solid #FFFFFF;
        color: #fff;
        margin-top: 40px;
    }
}


.index-ten{
    .rts-contact-page-form-area.contact-2 .mian-wrapper-form {
        padding: 50px;
        background: #f6f6f6;
    }
}